import React, { useState } from 'react';
import TbAvatarIcon from '../../../../icons/TbAvatarIcon';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import styleConfig from '../../../../shared/styles/styleConfig';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '145px',
    overflow: 'hidden',
    // paddingTop: `${(9 / 16) * 100}%`,
    background: 'black',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  identityContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.13) 30.73%, rgba(0, 0, 0, 0.51) 72.92%, rgba(0, 0, 0, 0.7) 100%)',
    borderRadius: '6px',
  },
  identity: {
    // background: '#2C2C2C',
    color: 'white',
    padding: '0.18em 0.3em',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#2C2C2C',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
}));

export default function LocalVideoPreview({ identity }: { identity: string }) {
  // const participantIdentity: string = identity.split(': ')[0];
  // const participantLanguageCode: string = identity.split(': ')[1];
  // console.log("identity", identity);

  const [userNameToDisplayMain, setUserNameToDisplay] = useState<string>('');
  const [languageToDisplayMain, setLanguageToDisplay] = useState<string>('');

  let userNameToDisplay;
  let languageToDisplay;
  let callHashToDisplay;
  // let urlRedirectToDisplay;

  function participantIdentityToDisplay() {
    const [username, language, callHash, userType] = identity.split(',');
    userNameToDisplay = valueToDisplay(username);
    languageToDisplay = valueToDisplay(language);
    callHashToDisplay = valueToDisplay(callHash);
    // urlRedirectToDisplay = getParamFromString(identity, "redirect_url:");

    setUserNameToDisplay(userNameToDisplay);
    setLanguageToDisplay(languageToDisplay);
  }

  function valueToDisplay(str: any) {
    return str.split(':')[1];
  }

  function getParamFromString(str: string, param: string): string {
    return str.split(param)[1];
  }

  useEffect(() => {
    if (identity) {
      participantIdentityToDisplay();
    }
  }, [identity]);

  const classes = useStyles();
  const customClasses = styleConfig();
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={classes.avatarContainer}>
            <TbAvatarIcon />
          </div>
        )}
      </div>

      <div className={classes.identityContainer}>
        <span className={classes.identity}>
          <LocalAudioLevelIndicator />
          <Typography
            variant="body1"
            color="inherit"
            component="span"
            className={`${customClasses.trimText} ${customClasses.trimTextSmall}`}
          >
            {/* <span className={customClasses.participantIdentity}>{participantIdentity}: </span>
            <span className={customClasses.participantLanguageCode}>{participantLanguageCode}</span> */}
            <span className={customClasses.participantIdentity}>{userNameToDisplayMain}: </span>
            <span className={customClasses.participantLanguageCode}>{languageToDisplayMain}</span>
          </Typography>
        </span>
      </div>
    </div>
  );
}
