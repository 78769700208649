// const API_KEY ='AIzaSyCu8wUG40zeDPqUhTew0P1SD3qqnFAFWpE'

export default function fetchTranslation(from: string | undefined, to: string, text: string) {
  return fetch(`/translate`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      from: from,
      to: to,
      text: text,
    }),
  });
}

// export function createTranslationURL(
//     from_: string | undefined,
//     to_: string,
//     text: string
// ): string {
//     let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
//     url += '&q=' + encodeURI(text);
//     url += `&source=${from_}`;
//     url += `&target=${to_}`;
//     return url;
// }
