import React, { SVGProps } from 'react';

export default function MicIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 12C10.6545 12 12 10.6545 12 9V4.5C12 2.83725 10.6613 1.48425 9.01575 1.48425C8.964 1.48425 8.91075 1.491 8.859 1.503C7.26975 1.578 6 2.89275 6 4.5V9C6 10.6545 7.3455 12 9 12Z"
        fill="#868E96"
      />
      <path
        d="M8.25 14.9482V16.5H9.75V14.9482C12.7043 14.577 15 12.0547 15 9H13.5C13.5 11.4818 11.4818 13.5 9 13.5C6.51825 13.5 4.5 11.4818 4.5 9H3C3 12.054 5.29575 14.577 8.25 14.9482Z"
        fill="#868E96"
      />
    </svg>
  );
}
