import React, { SVGProps } from 'react';

export default function VideoOnIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.5 5.25C13.5 4.42275 12.8273 3.75 12 3.75H3C2.17275 3.75 1.5 4.42275 1.5 5.25V12.75C1.5 13.5773 2.17275 14.25 3 14.25H12C12.8273 14.25 13.5 13.5773 13.5 12.75V10.2502L16.5 12.75V5.25L13.5 7.74975V5.25Z"
        fill="#868E96"
      />
    </svg>
  );
}
