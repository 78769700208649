import React from 'react';

export default function FileAttachmentIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3333 16L17.6569 23.6764C16.596 24.7373 15.1571 25.3333 13.6569 25.3333C10.5327 25.3333 8 22.8006 8 19.6764C8 18.1761 8.59599 16.7373 9.65685 15.6764L17.5621 7.77119C18.2693 7.06395 19.2286 6.66663 20.2288 6.66663C22.3116 6.66663 24 8.35507 24 10.4379C24 11.4381 23.6027 12.3973 22.8954 13.1045L15.219 20.781C14.8653 21.1346 14.3857 21.3333 13.8856 21.3333C12.8442 21.3333 12 20.4891 12 19.4477C12 18.9476 12.1987 18.468 12.5523 18.1143L20 10.6666"
        stroke="#868E96"
        strokeWidth="2"
      />
    </svg>
  );
}
