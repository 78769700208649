import React from 'react';

export default function SettingsIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75809 11.4532L3.25809 14.0483C3.46509 14.4068 3.92409 14.5298 4.28259 14.322L5.32959 13.7175C5.76459 14.0603 6.24534 14.3415 6.75084 14.5515V15.75C6.75084 16.164 7.08609 16.5 7.50084 16.5H10.5008C10.9156 16.5 11.2508 16.164 11.2508 15.75V14.5515C11.7563 14.3415 12.2363 14.0603 12.6721 13.7175L13.7191 14.322C14.0768 14.5283 14.5373 14.4045 14.7436 14.0483L16.2436 11.4532C16.4498 11.0962 16.3261 10.635 15.9698 10.4288L14.9408 9.834C14.9798 9.55725 15.0001 9.27825 15.0001 9C15.0001 8.72175 14.9798 8.44275 14.9393 8.166L15.9683 7.57125C16.3253 7.365 16.4491 6.90375 16.2421 6.54675L14.7421 3.95175C14.5351 3.59325 14.0761 3.47025 13.7176 3.678L12.6706 4.2825C12.2356 3.93975 11.7556 3.65775 11.2501 3.4485V2.25C11.2501 1.836 10.9148 1.5 10.5001 1.5H7.50009C7.08534 1.5 6.75009 1.836 6.75009 2.25V3.4485C6.24459 3.6585 5.76459 3.93975 5.32884 4.2825L4.28259 3.678C3.92334 3.47025 3.46509 3.59325 3.25809 3.95175L1.75809 6.54675C1.55184 6.90375 1.67559 7.365 2.03184 7.57125L3.06084 8.166C3.02034 8.44275 3.00009 8.72175 3.00009 9C3.00009 9.27825 3.02034 9.55725 3.06084 9.834L2.03184 10.4288C1.67484 10.635 1.55109 11.0962 1.75809 11.4532ZM9.00009 6C10.6546 6 12.0001 7.3455 12.0001 9C12.0001 10.6545 10.6546 12 9.00009 12C7.34559 12 6.00009 10.6545 6.00009 9C6.00009 7.3455 7.34559 6 9.00009 6Z"
        fill="#868E96"
      />
    </svg>
  );
}
