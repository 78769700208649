import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import ChatWindow from '../ChatWindow/ChatWindow';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import TbLogo from '../../icons/tbLogo';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;
  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight} - 15px) ${totalMobileSidebarHeight}`,
        // gridTemplateColumns: `unset`,
        // gridTemplateRows: `unset`,
      },
    },
    chatWindowOpen: {
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.chatWindowWidth}px`,
    },
    logoContainer: {
      position: 'absolute',
      left: '16px',
      bottom: '16px',
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        width: '100px',
      },
    },
  };
});

export default function Room() {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  return (
    <div className={clsx(classes.container, { [classes.chatWindowOpen]: isChatWindowOpen }) + ' ' + 'chat-window-open'}>
      {/* <div> */}
      <MainParticipant />
      <ParticipantList />
      {/* </div> */}

      <ChatWindow />
      <div className={classes.logoContainer}>
        <a href="http://2beinstore.com/">
          <TbLogo />
        </a>
      </div>
    </div>
  );
}
