import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  primaryButton: {
    backgroundColor: '#3A4FC2',
    borderRadius: '6px',
    padding: '13px 20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '-0.02em',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: '#5867bd',
    },
    '&.Mui-disabled': {
      backgroundColor: '#ECECEC',
    },
  },
  secondaryButton: {
    backgroundColor: '#E9ECEF',
    borderRadius: '6px',
    padding: '13px 20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '-0.02em',
    border: 'unset',
  },
  roomControlBtn: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '-0.02em',
    color: '#343A40',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#E9ECEF',
    },
    [theme.breakpoints.down('sm')]: {
      background: '#E9ECEF',
      borderRadius: '37px',
      // minWidth: '52px',
      minWidth: 'unset',
      marginRight: '4px',
      padding: '10px 12px',
    },
    '& .MuiButton-startIcon': {
      margin: '0',
    },
  },
  dangerButton: {
    backgroundColor: '#E03131',
    borderRadius: '6px',
    padding: '13px 20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '-0.02em',
    border: 'unset',
    color: '#fff',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#F03E3E',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '44px',
      marginRight: '4px',
      width: '44px',
      height: '44px',
      borderRadius: '50%',
      padding: '0',
      justifyContent: 'center',
    },
  },
  roomControlBtnCircle: {
    width: '50px',
    height: '50px',
    minWidth: '50px',
    padding: '0',
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '44px',
      height: '44px',
      minWidth: '44px',
    },
  },
  settingsBtn: {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
    },
  },
  btnSpace: {
    marginRight: '6px',
  },
  roomName: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '-0.02em',
    color: '#000',
  },
  h6: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#fff',
  },
  participantIdentity: {
    fontSize: '14px',
    letterSpacing: '-0.02em',
    lineHeight: '140%',
  },
  participantChatIdentity: {
    fontSize: '12px',
    lineHeight: '140%',
  },
  date: {
    lineHeight: '140%',
  },
  participantLanguageCode: {
    fontSize: '14px',
    letterSpacing: '-0.02em',
    textTransform: 'uppercase',
    lineHeight: '140%',
  },
  sendMessageButton: {
    backgroundColor: '#3A4FC2',
    borderRadius: '6px',
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  trimText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '265px',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '115px',
    },
  },
  trimTextSmall: {
    maxWidth: '215px',
  },
}));

export default useStyles;
