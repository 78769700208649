import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import styleConfig from '../../shared/styles/styleConfig';

import useFlipCameraToggle from '../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import { styled, useMediaQuery, Menu as MenuContainer, MenuItem } from '@material-ui/core';
import FlipCameraIcon from '../../icons/FlipCameraIcon';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  // marginRight: '3px',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 24px',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
      '& .button-text': {
        marginLeft: '3px',
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& p': {
        color: 'white',
        marginRight: '16px',
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    showText: {
      '& .button-text': {
        display: 'block',
      },
      [theme.breakpoints.down('sm')]: {
        '& .button-text': {
          display: 'none',
        },
      },
    },
    menuBarWrapper: {
      height: '72px',
      [theme.breakpoints.down('sm')]: {
        height: '65px',
      },
    },
    menuBarContainer: {
      padding: '12px 4px',
    },
    fullWidth: {
      [theme.breakpoints.down('sm')]: {
        // width: "100%,"
      },
    },
  })
);

interface userUrlData {
  urlRedirect: string;
  passcode: string;
}

export default function MenuBar({ urlRedirect, passcode }: userUrlData) {
  const classes = useStyles();
  const customClasses = styleConfig();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  return (
    <>
      {isSharingScreen && (
        <Grid container justify="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography className={customClasses.h6}>You are sharing your screen</Typography>
          <Button className={customClasses.dangerButton} onClick={() => toggleScreenShare()}>
            Stop Sharing
          </Button>
        </Grid>
      )}
      <footer className={`${classes.container} ${classes.menuBarWrapper} ${'menu-bar-wrapper'}`}>
        <Grid container alignItems="center" className={classes.menuBarContainer}>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1" className={customClasses.roomName} style={{ display: 'none' }}>
                {room!.name}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item className={`${classes.fullWidth}`}>
            <Grid container justify="space-between">
              <ToggleAudioButton
                disabled={isReconnecting}
                className={`${customClasses.roomControlBtn} ${classes.showText}`}
              />
              <ToggleVideoButton
                disabled={isReconnecting}
                className={`${customClasses.roomControlBtn} ${classes.showText}`}
              />
              {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
              {/* {<ToggleScreenShareButton disabled={isReconnecting} />} */}

              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}

              {flipCameraSupported && (
                <Button
                  className={customClasses.roomControlBtn}
                  disabled={flipCameraDisabled}
                  onClick={toggleFacingMode}
                >
                  <IconContainer>
                    <FlipCameraIcon />
                  </IconContainer>
                  <Hidden smDown>
                    <span className={'button-text'}>Flip Camera</span>
                  </Hidden>
                </Button>
              )}

              <Hidden mdUp>
                <Menu passcode={passcode} urlRedirect={urlRedirect} />
              </Hidden>
            </Grid>
          </Grid>
          {/* <Hidden smDown> */}
          <Hidden>
            <Grid style={{ flex: 1 }}>
              <Grid container justify="flex-end">
                <Hidden smDown>
                  <Menu passcode={passcode} urlRedirect={urlRedirect} />
                </Hidden>
                <EndCallButton urlRedirect={urlRedirect} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
