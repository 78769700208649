import React, { useState, useRef, useEffect } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import StartRecordingIcon from '../../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../../icons/StopRecordingIcon';
import SettingsIcon from '../../../icons/SettingsIcon';
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Select,
  makeStyles,
} from '@material-ui/core';

import { useAppState } from '../../../state';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import styleConfig from '../../../shared/styles/styleConfig';
import Languages from '../../../shared/languages';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    backgroundColor: '#ECECEC',
    borderRadius: '6px',
    width: '100%',
    '&::before': {
      borderBottom: 'unset',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'unset',
    },
  },
}));

interface userUrlData {
  urlRedirect: string;
  passcode: string;
}

export default function Menu(props: any) {
  const classes = useStyles();

  const customClasses = styleConfig();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { isFetching, updateRecordingRules, roomType } = useAppState();
  const { room } = useVideoContext();

  // console.log(room?.localParticipant?.identity);

  const isRecording = useIsRecording();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  const [participantLanguageCode, setParticipantLanguageCode] = useState<any>('');
  const [participantLanguageValue, setParticipantLanguageValue] = useState<string>('');
  const [participantCallHash, setParticipantCallHash] = useState<string>('');
  const [participantName, setParticipantName] = useState<string>('');
  const [participantType, setParticipantType] = useState<string>('');

  useEffect(() => {
    if (room) {
      const [username, language, callHash, type] = room.localParticipant.identity.split(',');

      setParticipantName(valueToDisplay(username));
      setParticipantCallHash(valueToDisplay(callHash));
      setParticipantType(valueToDisplay(type));
      setParticipantLanguageCode(valueToDisplay(language));

      const languageData: string[] | undefined = Languages.map(item => {
        if (item.indexOf(participantLanguageCode) > -1) {
          return item;
        }
      }).filter(item => item !== undefined)[0];

      if (languageData) {
        setParticipantLanguageValue(languageData[0]);
      }
    }
  }, [participantLanguageCode, participantLanguageValue, participantCallHash, participantName, participantType]);

  function valueToDisplay(array: any) {
    return array.split(':')[1];
  }

  const handleParticipantLanguage = (event: any) => {
    const value = event.target.value;
    let lang;
    setParticipantLanguageCode(value);
    for (let i = 0; i < Languages.length; i++) {
      const langIndex: number = Languages[i].indexOf(value);
      if (langIndex !== -1) {
        lang = Languages[i][0];
        setParticipantLanguageValue(Languages[i][0]);
      }
    }

    // console.log(`${room?.name}?passcode=${props.passcode}&call_hash=${participantCallHash}&username=${participantName}&type=${participantType}&language_code=${value}&language_name=${lang}&redirect_url=${props.urlRedirect}`)
    window.location.href = `${window.location.href}${room?.name}?passcode=${props.passcode}&call_hash=${participantCallHash}&username=${participantName}&type=${participantType}&language_code=${value}&language_name=${lang}&redirect_url=${props.urlRedirect}&to_room=true`;
  };

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={`${props.buttonClassName} ${customClasses.roomControlBtn} ${customClasses.settingsBtn} ${customClasses.btnSpace}`}
        data-cy-more-button
      >
        {isMobile ? (
          <MoreIcon />
        ) : (
          <>
            More
            <ExpandMoreIcon />
          </>
        )}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            style={{ display: 'none' }}
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
            <Typography variant="body1">{isRecording ? 'Stop' : 'Start'} Recording</Typography>
          </MenuItem>
        )}
        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode} style={{ display: 'none' }}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">Flip Camera</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>

        <MenuItem>
          <Select
            className={classes.selectContainer}
            onChange={handleParticipantLanguage}
            value={participantLanguageCode}
            name={participantLanguageValue}
          >
            {Languages.map(language => (
              <MenuItem key={language[1]} value={language[1]}>
                {language[0]}
              </MenuItem>
            ))}
          </Select>
        </MenuItem>

        <MenuItem style={{ display: 'none' }} onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem>
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
