import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styleConfig from '../../../../shared/styles/styleConfig';

const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1.425em 0 0.083em',
      fontSize: '12px',
      color: '#606B85',
      width: 'calc(100% - 16px)',
    },
    localMessage: {},
    anotherMessage: {
      margin: '0 0 0 auto',
    },
  })
);

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}

export default function MessageInfo({ author, dateCreated, isLocalParticipant }: MessageInfoProps) {
  const classes = useStyles();
  const customClasses = styleConfig();

  // const participantIdentity: string = author.split(': ')[0];
  // const participantLanguageCode: string = author.split(': ')[1];

  let userNameToDisplay;
  let languageToDisplay;
  let callHashToDisplay;

  const participantIdentityToDisplay = () => {
    const [username, language, callHash] = author.split(',');

    userNameToDisplay = valueToDisplay(username);
    languageToDisplay = valueToDisplay(language);
    callHashToDisplay = valueToDisplay(callHash);
  };

  function valueToDisplay(array: any) {
    return array.split(':')[1];
  }

  participantIdentityToDisplay();

  return (
    <div
      className={`${classes.messageInfoContainer} ${'message-info-container'} ${
        isLocalParticipant ? classes['localMessage'] : classes['anotherMessage']
      }`}
    >
      {isLocalParticipant ? (
        <React.Fragment>
          <div>
            <span className={customClasses.participantChatIdentity}>
              {/* {participantIdentity}{' '} */}
              {userNameToDisplay}{' '}
              {/* <span className={customClasses.participantLanguageCode}>{participantLanguageCode}</span> (You) */}
              <span className={customClasses.participantLanguageCode}>{languageToDisplay}</span> (You)
            </span>
          </div>
          <span className={customClasses.date}>{dateCreated}</span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className={customClasses.date}>{dateCreated}</span>
          <div>
            <span className={customClasses.participantChatIdentity}>
              {/* {participantIdentity}{' '} */}
              {userNameToDisplay}{' '}
              {/* <span className={customClasses.participantLanguageCode}>{participantLanguageCode}</span> */}
              <span className={customClasses.participantLanguageCode}>{languageToDisplay}</span>
            </span>
          </div>
        </React.Fragment>
      )}
      {/* <div>
        {isLocalParticipant ? (
          <span className={customClasses.participantChatIdentity}>
            {participantIdentity}{' '}
            <span className={customClasses.participantLanguageCode}>{participantLanguageCode}</span> (You)
          </span>
        ) : (
          <span className={customClasses.participantChatIdentity}>
            {participantIdentity}{' '}
            <span className={customClasses.participantLanguageCode}>{participantLanguageCode}</span>
          </span>
        )}
      </div>
      <div>{dateCreated}</div> */}
    </div>
  );
}
