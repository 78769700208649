import React from 'react';

export default function MainAvatarIcon() {
  return (
    <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="61" cy="61" r="61" fill="#343A40" />
      <path
        d="M50.4425 48.0961C50.4425 53.917 55.1793 58.6538 61.0002 58.6538C66.821 58.6538 71.5578 53.917 71.5578 48.0961C71.5578 42.2753 66.821 37.5385 61.0002 37.5385C55.1793 37.5385 50.4425 42.2753 50.4425 48.0961ZM79.7694 82.1154H82.1155V79.7692C82.1155 70.7154 74.7463 63.3461 65.6925 63.3461H56.3078C47.2517 63.3461 39.8848 70.7154 39.8848 79.7692V82.1154H42.2309H44.5771H77.4232H79.7694Z"
        fill="white"
      />
    </svg>
  );
}
