import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

interface userUrlData {
  urlRedirect: string;
  setUrlRedirect: (urlRedirect: string) => void;
  setPasscode: (passcode: string) => void;
}

export default function PreJoinScreens({ urlRedirect, setUrlRedirect, setPasscode }: userUrlData) {
  const defaultLanguageCode: string = 'en-US';
  const defaultLanguageValue: string = 'English (United States)';
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [participantLanguageCode, setParticipantLanguageCode] = useState<string>(defaultLanguageCode);
  const [participantLanguageValue, setParticipantLanguageValue] = useState<string>(defaultLanguageValue);

  const [mediaError, setMediaError] = useState<Error>();

  const [userType, setUserType] = useState<string | null>('');

  const [callHash, setCallHash] = useState<string>('');

  const [toRoom, setToRoom] = useState<boolean>(false);

  // if (room) {
  //   room.localParticipant.lang = participantLanguageCode;
  // }
  // console.log("room", room);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!window.location.origin.includes('twil.io')) {
        window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
      }
      setStep(Steps.deviceSelectionStep);
    }, 0);
  }, []);

  const getURLParam = function(param: any) {
    const URLParameters: string = window.location.search.substring(1);
    const urlParams: URLSearchParams = new URLSearchParams(URLParameters);
    return urlParams.get(param);
  };

  useEffect(() => {
    const url: string | null = getURLParam('redirect_url');
    if (url) {
      setUrlRedirect(url);
    }
  }, []);

  useEffect(() => {
    const passcode: string | null = getURLParam('passcode');
    if (passcode) {
      setPasscode(passcode);
    }
  }, []);

  return (
    <IntroContainer loginStep={step}>
      <MediaErrorSnackbar error={mediaError} />
      {/* {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          participantLanguageCode={participantLanguageCode}
          participantLanguageValue={participantLanguageValue}
          // userStep={userStep}
          setName={setName}
          setRoomName={setRoomName}
          setParticipantLanguageCode={setParticipantLanguageCode}
          setParticipantLanguageValue={setParticipantLanguageValue}
          // setUserStep={setUserStep}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={`${name}: ${participantLanguageCode}`}
          roomName={roomName}
          participantLanguageValue={participantLanguageValue}
          setStep={setStep}
        />
      )} */}

      <RoomNameScreen
        name={name}
        roomName={roomName}
        participantLanguageCode={participantLanguageCode}
        participantLanguageValue={participantLanguageValue}
        setName={setName}
        setRoomName={setRoomName}
        setParticipantLanguageCode={setParticipantLanguageCode}
        setParticipantLanguageValue={setParticipantLanguageValue}
        userType={userType}
        setUserType={setUserType}
        handleSubmit={handleSubmit}
        callHash={callHash}
        setCallHash={setCallHash}
        setToRoom={setToRoom}
      />

      <DeviceSelectionScreen
        // name={`${name}: ${participantLanguageCode}`}
        name={`${name},language:${participantLanguageCode},call_hash:${callHash},type:${userType}`}
        roomName={roomName}
        participantLanguageValue={participantLanguageValue}
        userType={userType}
        setStep={setStep}
        toRoom={toRoom}
      />
    </IntroContainer>
  );
}
