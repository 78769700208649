import React, { SVGProps } from 'react';

export default function MicOffIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.2802 15.2198L13.7302 12.6698C14.5215 11.655 15 10.3845 15 9H13.5C13.5 9.96975 13.1857 10.8653 12.6607 11.6003L11.574 10.5128C11.8387 10.068 12 9.555 12 9V4.5C12 2.83725 10.6612 1.48425 9.01573 1.48425C8.96323 1.48425 8.91073 1.491 8.85898 1.503C7.26973 1.578 5.99998 2.89275 5.99998 4.5V4.9395L2.78023 1.71975L1.71973 2.78025L15.2197 16.2803L16.2802 15.2198ZM4.49998 9H2.99998C2.99998 12.054 5.29498 14.577 8.24998 14.9483V16.5H9.74998V14.9483C10.3417 14.874 10.905 14.7098 11.4307 14.4758L10.269 13.3148C9.86623 13.4333 9.44098 13.5 8.99998 13.5C6.51823 13.5 4.49998 11.4818 4.49998 9Z"
        fill="#E03131"
      />
      <path d="M6.00513 9.05025C6.03213 10.6635 7.33638 11.9677 8.94963 11.9947L6.00513 9.05025Z" fill="#E03131" />
    </svg>
  );
}
