import React from 'react';

export default function ChatIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 4H4C2.897 4 2 4.897 2 6V24L6 20H20C21.103 20 22 19.103 22 18V6C22 4.897 21.103 4 20 4ZM14 15H7V13H14V15ZM17 11H7V9H17V11Z"
        fill="#868E96"
      />
    </svg>
  );
}
