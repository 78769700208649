import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '../../../icons/CloseIcon';

import useChatContext from '../../../hooks/useChatContext/useChatContext';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '85px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '24px 16px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 1px 1px rgba(52, 58, 64, 0.24)',
    },
    text: {
      fontWeight: 'bold',
      fontSize: '18px',
      letterSpacing: '-0.02em',
      color: '#343A40',
    },
    closeChatWindow: {
      cursor: 'pointer',
      display: 'flex',
      background: 'transparent',
      border: '0',
      padding: '0.4em',
    },
  })
);

export default function ChatWindowHeader() {
  const classes = useStyles();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className={`${classes.container} ${'chat-header'}`}>
      <div className={`${classes.text} ${'chat-title'}`}>Chat</div>
      <button
        className={`${classes.closeChatWindow} ${'chat-close-button'}`}
        onClick={() => setIsChatWindowOpen(false)}
      >
        <CloseIcon />
      </button>
    </div>
  );
}
