// TODO: Combine RoomNameScreen and DeviceSelectionScreen.

import React from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import styleConfig from '../../../shared/styles/styleConfig';
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    // width: '100%',
    // border: '2px solid #CED4DA',
    // margin: '0 0 8px 0',
    borderRadius: '6px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '-0.02em',
    height: '35px',
    // color: "#868E96",
  },
  localPreviewContainer: {
    // paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& button': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        // margin: '0.5em 0',
        height: '43px',
      },
    },
  },
  buttonBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '5px 0',
    '& .MuiButton-startIcon': {
      margin: '0',
    },
    '& .MuiButton-root': {
      minWidth: '35px',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      // margin: '1.5em 0 1em',
      margin: '10px 0',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  tbH5: {
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '-0.02em',
    marginBottom: '16px',
  },
  tbBody1: {
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '-0.02em',
    color: '#6C6C6C',
  },
  previewContainer: {
    // marginTop: '20px',
    flexDirection: 'column',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
  },
  primaryButton: {
    padding: '0 12px',
    marginLeft: '-6px',
    '& svg': {
      marginRight: '-3px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      '& svg': {
        width: '50px',
        height: '50px',
      },
    },
  },
  hideText: {
    '& .button-text': {
      display: 'none',
    },
  },
}));

const SHOP = 'shop';
const USER = 'user';

const ParticipantType = {
  SHOP,
  USER,
};

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  participantLanguageValue: string;
  userType?: string | null;
  setStep: (step: Steps) => void;

  toRoom: boolean;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  participantLanguageValue,
  userType,
  setStep,
  toRoom,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const customClasses = styleConfig();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const isNameAndRoom: boolean = name && name.split(':')[0] !== '' && roomName ? false : true;

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  useEffect(() => {
    if (toRoom) {
      handleJoin();
    }
  }, [toRoom]);

  useEffect(() => {
    // console.log("userType", userType);
    if (userType === ParticipantType.SHOP) {
      // handleJoin();
    }
  }, [userType]);

  // TODO: Refactor this functional.
  if (isFetching || isConnecting) {
    return (
      <div style={{ display: 'none' }}>
        <Grid container justify="center" alignItems="center" direction="column" style={{ height: '100%' }}>
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
          <div style={{ display: 'none' }}>
            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
              Joining Meeting
            </Typography>
          </div>
        </Grid>
      </div>
    );
  }

  const tooltipMessage = 'Enter the name of the room and your name';

  return (
    <>
      <Typography variant="h5" className={classes.tbH5} style={{ display: 'none' }}>
        Call {roomName}
      </Typography>

      <Typography variant="body1" className={classes.tbBody1} style={{ display: 'none' }}>
        Language {participantLanguageValue}
      </Typography>

      <Grid container justify="center" className={classes.previewContainer}>
        <Grid item md={12} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.buttonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={`${classes.mobileButton} ${classes.hideText}`} disabled={disableButtons} />
              <ToggleVideoButton className={`${classes.mobileButton} ${classes.hideText}`} disabled={disableButtons} />
            </Hidden>

            <Hidden smDown>
              <ToggleAudioButton className={`${classes.deviceButton} ${classes.hideText}`} disabled={disableButtons} />
              <ToggleVideoButton className={`${classes.deviceButton} ${classes.hideText}`} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between">
            {/* <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div> */}
            <div className={classes.joinButtons}>
              <Button
                className={customClasses.secondaryButton}
                onClick={() => setStep(Steps.roomNameStep)}
                style={{ display: 'none' }}
              >
                Cancel
              </Button>

              <Tooltip
                title={isNameAndRoom ? tooltipMessage : ''}
                placement="top"
                PopperProps={{ disablePortal: true }}
              >
                <span
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '3px',
                  }}
                >
                  <Button
                    className={customClasses.primaryButton + ' ' + classes.primaryButton}
                    variant="contained"
                    color="primary"
                    data-cy-join-now
                    onClick={handleJoin}
                    disabled={disableButtons || isNameAndRoom}
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 19 19"
                      fill="transparent"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="9.5" cy="9.5" r="9.5" fill="transparent"></circle>
                      <path
                        d="M15 7.70648C15 7.39556 14.6608 7.20351 14.3942 7.36348L10.5717 9.657C10.3127 9.81236 10.3127 10.1876 10.5717 10.343L14.3942 12.6365C14.6608 12.7965 15 12.6044 15 12.2935V7.70648Z"
                        fill="white"
                      ></path>
                      <path
                        d="M10.0538 7H4.94624C4.42364 7 4 7.38376 4 7.85714V12.1429C4 12.6162 4.42364 13 4.94624 13H10.0538C10.5764 13 11 12.6162 11 12.1429V7.85714C11 7.38376 10.5764 7 10.0538 7Z"
                        fill="white"
                      ></path>
                    </svg>
                    Go
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
