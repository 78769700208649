import React from 'react';

export default function FlipCameraIcon() {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7428 2.96669H18.3001C19.3909 2.96669 20.2779 3.85373 20.2779 4.94448V15.8223C20.2779 16.9131 19.3909 17.8001 18.3001 17.8001H2.47779C1.38704 17.8001 0.5 16.9131 0.5 15.8223V4.94448C0.5 3.85373 1.38704 2.96669 2.47779 2.96669H5.03508L7.71202 0.289746C7.89694 0.103834 8.14812 0 8.41117 0H12.3667C12.6298 0 12.881 0.103834 13.0659 0.289746L15.7428 2.96669ZM10.3889 4.19451C9.97468 4.19451 9.63889 4.5303 9.63889 4.94451C9.63889 5.35873 9.97468 5.69451 10.3889 5.69451C12.5493 5.69451 14.2537 7.37655 14.2537 9.39454C14.2537 10.0392 14.0815 10.6476 13.7759 11.1781V10.9838C13.7759 10.5696 13.4402 10.2338 13.0259 10.2338C12.6117 10.2338 12.2759 10.5696 12.2759 10.9838V13.2089C12.2759 13.6231 12.6117 13.9589 13.0259 13.9589H15.3334C15.7476 13.9589 16.0834 13.6231 16.0834 13.2089C16.0834 12.7946 15.7476 12.4589 15.3334 12.4589H14.7237C15.3694 11.6019 15.7537 10.5434 15.7537 9.39454C15.7537 6.49717 13.3259 4.19451 10.3889 4.19451ZM5.44441 4.83023C5.0302 4.83023 4.69441 5.16602 4.69441 5.58023C4.69441 5.99444 5.0302 6.33023 5.44441 6.33023H6.05412C5.40835 7.18718 5.02404 8.24569 5.02404 9.39454C5.02404 12.2919 7.45191 14.5946 10.3889 14.5946C10.8031 14.5946 11.1389 14.2588 11.1389 13.8446C11.1389 13.4304 10.8031 13.0946 10.3889 13.0946C8.22845 13.0946 6.52404 11.4125 6.52404 9.39454C6.52404 8.74985 6.69633 8.14152 7.00183 7.61095V7.80525C7.00183 8.21946 7.33762 8.55525 7.75183 8.55525C8.16605 8.55525 8.50183 8.21946 8.50183 7.80525V5.58023C8.50183 5.16602 8.16605 4.83023 7.75183 4.83023H5.44441Z"
        fill="#373737"
      />
    </svg>

    // <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
    //   <g fill="none" fillRule="evenodd">
    //     <g fill="#707578">
    //       <path
    //         d="M12 3.5c4.694 0 8.5 3.806 8.5 8.5s-3.806 8.5-8.5 8.5-8.5-3.806-8.5-8.5S7.306 3.5 12 3.5zm0 1c-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5 4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5zm1.367 3.429C14.959 8.492 16.1 10.215 16.1 12c0 1.16-.481 2.294-1.256 3.1h.356c.245 0 .45.177.492.41l.008.09c0 .245-.177.45-.41.492l-.09.008h-2.008l-.048-.003-.034-.005c-.088-.017-.167-.055-.233-.11l-.06-.06c-.037-.044-.067-.096-.087-.151l-.001-.004-.012-.04c-.004-.012-.007-.024-.009-.037v-.005c-.005-.024-.006-.042-.007-.06L12.7 13.6c0-.276.224-.5.5-.5.245 0 .45.177.492.41l.008.09v1.176c.833-.6 1.4-1.676 1.4-2.776 0-1.373-.886-2.71-2.067-3.129-.26-.092-.396-.377-.304-.638.092-.26.378-.396.638-.304zm-2.559-.03c.03.001.058.004.086.01l.062.016.018.006c.04.015.075.034.108.056.086.059.152.143.188.242l.001.004.008.025c.006.017.01.034.013.052l.008.09v2c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41l-.008-.09V9.224c-.833.6-1.4 1.676-1.4 2.776 0 1.373.886 2.71 2.067 3.129.26.092.396.377.304.638-.092.26-.378.396-.638.304C9.041 15.508 7.9 13.785 7.9 12c0-1.16.481-2.294 1.255-3.1H8.8c-.245 0-.45-.177-.492-.41L8.3 8.4c0-.245.177-.45.41-.492L8.8 7.9h2.008z"
    //         transform="translate(-233 -689) translate(-1 673) translate(37 16) translate(197.298)"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
}
