import React from 'react';

export default function TbAvatarIcon() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="26" r="26" fill="black" />
      <path
        d="M21.5 20.5C21.5 22.981 23.519 25 26 25C28.481 25 30.5 22.981 30.5 20.5C30.5 18.019 28.481 16 26 16C23.519 16 21.5 18.019 21.5 20.5ZM34 35H35V34C35 30.141 31.859 27 28 27H24C20.14 27 17 30.141 17 34V35H18H19H33H34Z"
        fill="white"
      />
    </svg>
  );
}
