import React from 'react';

export default function ScreenShareIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0001 12.3441C20.0008 12.6607 19.9093 12.971 19.7362 13.2389C19.5631 13.5068 19.3156 13.7213 19.0224 13.8575L6.45794 19.66C6.22798 19.7823 5.97323 19.8536 5.71179 19.8691C5.42569 19.8685 4.27417 19.1036 4.27417 19.1036C4.27408 19.0683 4.03358 17.7656 4.15088 17.5112L6.1063 13.1802H10.8576C11.0851 13.1802 11.3032 13.0921 11.4641 12.9353C11.6249 12.7785 11.7153 12.5659 11.7153 12.3441C11.7153 12.1224 11.6249 11.9097 11.4641 11.7529C11.3032 11.5961 11.0851 11.508 10.8576 11.508H6.1063L4.15088 7.21879C4.00919 6.9071 4.45402 5.68035 4.51999 5.34554C4.51999 5.34554 6.13293 4.88304 6.44936 5.0282L19.0138 10.8308C19.3086 10.9657 19.5579 11.1797 19.7326 11.4477C19.9072 11.7157 20 12.0266 20.0001 12.3441Z"
        fill="#CED4DA"
      />
    </svg>
  );
}
