export default [
  ['Afrikaans (Suid-Afrika)', 'af-ZA'],
  ['አማርኛ (ኢትዮጵያ)', 'am-ET'],
  ['Հայ (Հայաստան)', 'hy-AM'],
  ['Azərbaycan (Azərbaycan)', 'az-AZ'],
  ['Bahasa Indonesia (Indonesia)', 'id-ID'],
  ['Bahasa Melayu (Malaysia)', 'ms-MY'],
  ['বাংলা (বাংলাদেশ)', 'bn-BD'],
  ['Català (Espanya)', 'ca-ES'],
  ['Čeština (Česká republika)', 'cs-CZ'],
  ['Dansk (Danmark)', 'da-DK'],
  ['Deutsch (Deutschland)', 'de-DE'],
  ['English (United States)', 'en-US'],
  ['Español (Argentina)', 'es-AR'],
  ['Euskara (Espainia)', 'eu-ES'],
  ['Filipino (Pilipinas)', 'fil-PH'],
  ['Français (France)', 'fr-FR'],
  ['Galego (España)', 'gl-ES'],
  ['ქართული (საქართველო)', 'ka-GE'],
  ['ગુજરાતી (ભારત)', 'gu-IN'],
  ['Hrvatski (Hrvatska)', 'hr-HR'],
  ['IsiZulu (Ningizimu Afrika)', 'zu-ZA'],
  ['Íslenska (Ísland)', 'is-IS'],
  ['Italiano (Italia)', 'it-IT'],
  ['Jawa (Indonesia)', 'jv-ID'],
  ['ಕನ್ನಡ (ಭಾರತ)', 'kn-IN'],
  ['ភាសាខ្មែរ (កម្ពុជា)', 'km-KH'],
  ['ລາວ (ລາວ)', 'lo-LA'],
  ['Latviešu (latviešu)', 'lv-LV'],
  ['Lietuvių (Lietuva)', 'lt-LT'],
  ['Magyar (Magyarország)', 'hu-HU'],
  ['മലയാളം (ഇന്ത്യ)', 'ml-IN'],
  ['मराठी (भारत)', 'mr-IN'],
  ['Nederlands (Nederland)', 'nl-NL'],
  ['नेपाली (नेपाल)', 'ne-NP'],
  ['Norsk bokmål (Norge)', 'nb-NO'],
  ['Polski (Polska)', 'pl-PL'],
  ['Português (Portugal)', 'pt-PT'],
  ['Română (România)', 'ro-RO'],
  ['සිංහල (ශ්රී ලංකාව)', 'si-LK'],
  ['Slovenčina (Slovensko)', 'sk-SK'],
  ['Urang (Indonesia)', 'su-ID'],
  ['Swahili (Tanzania)', 'sw-TZ'],
  ['Suomi (Suomi)', 'fi-FI'],
  ['Svenska (Sverige)', 'sv-SE'],
  ['தமிழ் (இந்தியா)', 'ta-IN'],
  ['తెలుగు (భారతదేశం)', 'te-IN'],
  ['Tiếng Việt (Việt Nam)', 'vi-VN'],
  ['Türkçe (Türkiye)', 'tr-TR'],
  ['اردو (پاکستان)', 'ur-PK'],
  ['Ελληνικά (Ελλάδα)', 'el-GR'],
  ['Български (България)', 'bg-BG'],
  ['Русский (Россия)', 'ru-RU'],
  ['Српски (Србија)', 'sr-RS'],
  ['Українська (Україна)', 'uk-UA'],
  ['עברית (ישראל)', 'he-IL'],
  ['العربية (إسرائيل)', 'ar-IL'],
  ['فارسی (ایران)', 'fa-IR'],
  ['हिन्दी (भारत)', 'hi-IN'],
  ['ไทย (ประเทศไทย)', 'th-TH'],
  ['한국어 (대한민국)', 'ko-KR'],
  ['國語 (台灣)', 'cmn-Hant-TW'],
  ['廣東話 (香港)', 'yue-Hant-HK'],
  ['日本語（日本）', 'ja-JP'],
  ['普通話 (香港)', 'cmn-Hans-HK'],
  ['普通话 (中国大陆)', 'cmn-Hans-CN'],
];
