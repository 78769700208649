import React, { useState } from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';

import { makeStyles } from '@material-ui/core';
import useChatContext from './hooks/useChatContext/useChatContext';

const useStyles = makeStyles((theme: Theme) => {
  return {
    mainWrapper: {
      [theme.breakpoints.down('sm')]: {
        '& .chat-open': {
          padding: '0',
          // height: "calc(100% - 65px)",
          '& .chat-window-open': {
            display: 'flex',
            // justifyContent: "space-between",
            flexWrap: 'wrap',
            gridTemplateColumns: `unset`,
            gridTemplateRows: `unset`,
            backgroundColor: '#fff',
            height: 'calc(100% - 65px)',
          },
          '& .chat-header': {
            justifyContent: 'flex-end',
            position: 'relative',
            height: '140px',
            display: 'none',
          },
          '& .chat-close-button': {
            display: 'none',
          },
          '& .chat-message-list-container': {
            flex: 'unset',
            // height: 'calc(100% - 210px)',
            borderTop: '1px solid #e4e7e9',
            height: '100%',
            // minHeight: "290px",
          },
          '& .message-container': {
            padding: '5px 10px',
          },
          '& .chat-message-list': {
            padding: '5px 16px',
          },
          '& .chat-input-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px 8px',
            maxHeight: '60px',
            overflow: 'hidden',
          },
          '& .chat-text-area-container': {
            width: '100%',
            flex: '2',
            border: '1px solid #CED4DA',
            borderRadius: '6px',
            margin: '0',
          },
          '& .chat-grid-container': {
            flex: '1',
          },
          '& .chat-button-container': {
            margin: '0',
          },
          '& .chat-send-message-button': {
            minWidth: '63px',
            height: '44px',
            borderRadius: '6px',
          },
          '& .chat-file-attachment-button': {
            height: '44px',
          },
          '& .chat-title': {
            display: 'none',
          },
          '& .main-video-wrapper': {
            zIndex: '999',
            // width: '200px',
            flex: '0 0 calc(50% - 10px)',
            // height: '140px',
            // minHeight: '150px',
            // minHeight: '100px',
            // height: 'auto',
            height: '30%',
            marginRight: '10px',
            '& .identity-wrapper': {
              display: 'none',
            },
            '& .avatar-container': {
              '& svg': {
                width: '50px',
                height: '50px',
              },
            },
          },
          '& .main-video-container': {
            padding: '6px',
          },
          '& .menu-bar-wrapper': {
            zIndex: '999',
          },
          '& .participants-wrapper': {
            zIndex: '999',
            // gridArea: '1 / 1 / 3 / 3',
            // gridArea: '1 / 1 / 3 / 4',
            // marginLeft: '205px',
            flex: '0 0 50%',
            // minHeight: '150px',
            // minHeight: '100px',
            // height: '140px',
            // height: 'auto',
            height: '30%',
          },
          '& .participants-scroll-container': {
            width: '100%',
          },
          '& .participants-container': {
            padding: '0',
            width: '100%',
          },
          '& .participant-container': {
            // width: '200px',
            // flex: "0 0 50%",
            // height: '140px',
            height: '100%',
            padding: '0',
            borderRadius: '0',
            // width: "200px",
            width: '100%',
            flex: '0 0 calc(100% - 8px)',
          },
          '& .participant-info-row-bottom': {
            display: 'none',
          },
          '& .participant-pin': {
            marginTop: '-10px',
          },
          '&. .chat-window-container': {},
        },
        '& .menu-bar-wrapper': {
          '& .to-be-icon_mobile': {
            '& path': {
              fill: '#373737',
            },
          },
          '& .to-be-icon_mobile-big': {
            width: '24px',
            height: '24px',
          },
        },
        '& .message-info-container': {
          padding: '3px 0 0',
        },
      },
    },
  };
});

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const headerHeight: number = 52;

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight - headerHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState();
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const [urlRedirect, setUrlRedirect] = useState<string>('');
  const [passcode, setPasscode] = useState<string>('');

  return (
    <Container className={classes.mainWrapper} style={{ height }}>
      {/* <Container style={{ "height": "100vh" }}> */}
      {roomState === 'disconnected' ? (
        <PreJoinScreens urlRedirect={urlRedirect} setUrlRedirect={setUrlRedirect} setPasscode={setPasscode} />
      ) : (
        <Main className={`${isChatWindowOpen === true ? 'chat-open' : ''}`}>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar urlRedirect={urlRedirect} passcode={passcode} />
        </Main>
      )}
    </Container>
  );
}
