import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Conversation } from '@twilio/conversations/lib/conversation';
import FileAttachmentIcon from '../../../icons/FileAttachmentIcon';
import { isMobile } from '../../../utils';
import SendMessageIcon from '../../../icons/SendMessageIcon';
import Snackbar from '../../Snackbar/Snackbar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import styleConfig from '../../../shared/styles/styleConfig';

import fetchTranslation from '../../../state/useTranslation/useTranslation';

const useStyles = makeStyles(theme => ({
  chatInputContainer: {
    // borderTop: '1px solid #e4e7e9',
    borderBottom: '1px solid #e4e7e9',
    boxShadow: '0px -1px 1px rgb(52 58 64 / 24%)',
    padding: '16px',
  },
  textArea: {
    width: '100%',
    border: '0',
    resize: 'none',
    fontSize: '14px',
    fontFamily: 'Inter',
    outline: 'none',
  },
  button: {
    padding: '0',
    width: '40px',
    height: '40px',
    minWidth: 'auto',
    '&:disabled': {
      background: 'none',
      '& path': {
        fill: '#d8d8d8',
      },
    },
  },
  buttonContainer: {
    margin: '18px 0 0 0',
    display: 'flex',
  },
  fileButtonContainer: {
    position: 'relative',
    marginRight: '6px',
  },
  fileButtonLoadingSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textAreaContainer: {
    display: 'flex',
    marginTop: '0.4em',
    padding: '10px',
    border: '2px solid transparent',
  },
  isTextareaFocused: {
    // borderColor: theme.palette.primary.main,
    borderColor: '#CED4DA',
    borderRadius: '6px',
  },
}));

interface ChatInputProps {
  conversation: Conversation;
  isChatWindowOpen: boolean;
}

const ALLOWED_FILE_TYPES =
  'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv';

export default function ChatInput({ conversation, isChatWindowOpen }: ChatInputProps) {
  const { room } = useVideoContext();
  const customClasses = styleConfig();

  const [userNameToDisplayMain, setUserNameToDisplay] = useState<string>('');
  const [languageToDisplayMain, setLanguageToDisplay] = useState<string>('');
  const [localParticipantLangSplitCodeMain, setlocalParticipantLangSplitCodeMain] = useState<string>('');

  let userNameToDisplay;
  let languageToDisplay;
  let callHashToDisplay;

  function participantIdentityToDisplay() {
    if (room) {
      const [username, language, callHash] = room.localParticipant.identity.split(',');

      userNameToDisplay = valueToDisplay(username);
      languageToDisplay = valueToDisplay(language);
      callHashToDisplay = valueToDisplay(callHash);

      setUserNameToDisplay(userNameToDisplay);
      setLanguageToDisplay(languageToDisplay);
      const lang = languageToDisplay.split('-')[0];
      setlocalParticipantLangSplitCodeMain(lang);

      // console.log(userNameToDisplay, languageToDisplay, callHashToDisplay);
      // console.log("lang", lang);
    }
  }

  function valueToDisplay(array: any) {
    return array.split(':')[1];
  }

  useEffect(() => {
    participantIdentityToDisplay();
  }, []);

  const localParticipantLangCode: string | undefined = room?.localParticipant.identity.split(': ')[1];
  const localParticipantLangSplitCode: string | undefined = localParticipantLangCode?.split('-')[0];

  let participantsLangs: string[] = [];
  room?.participants.forEach(elem => {
    const [username, language, callHash] = elem.identity.split(',');
    languageToDisplay = valueToDisplay(language);
    // console.log(languageToDisplay.split('-')[0])
    participantsLangs.push(languageToDisplay.split('-')[0]);
  });
  participantsLangs = participantsLangs.filter((v, i, a) => a.indexOf(v) === i);

  const classes = useStyles();
  const [messageBody, setMessageBody] = useState('');
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  // console.log("isTextareaFocused", isTextareaFocused);

  // useEffect(() => {

  // }, [isTextareaFocused]);

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(event.target.value);
  };

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      handleSendMessage(messageBody);
    }
  };

  function checkLink(message: string): boolean {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(message);
  }

  const handleSendMessage = (message: string) => {
    if (isValidMessage) {
      setMessageBody('');
      conversation.sendMessage('Origin ' + languageToDisplayMain + ': ' + message.trim());
      // FIX participant if local equal remote
      if (localParticipantLangSplitCodeMain != null && participantsLangs.includes(localParticipantLangSplitCodeMain)) {
        let index = participantsLangs.indexOf(localParticipantLangSplitCodeMain);
        participantsLangs.splice(index, 1);
      }

      for (let i = 0; i < participantsLangs.length; i++) {
        fetchTranslation(localParticipantLangSplitCodeMain, participantsLangs[i], message)
          .then(response => response.json())
          .then(response => {
            console.log('response', 'Translated to ' + participantsLangs[i] + ': ' + response.trim());

            conversation.sendMessage('Translated to ' + participantsLangs[i] + ': ' + response.trim());
            setMessageBody('');
          })
          .catch(error => {
            console.log(error);
          });
      }
      // conversation.sendMessage(message.trim());
      // setMessageBody('');
    }
  };

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      var formData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setFileSendError(null);
      conversation
        .sendMessage(formData)
        .catch(e => {
          if (e.code === 413) {
            setFileSendError('File size is too large. Maximum file size is 150MB.');
          } else {
            setFileSendError('There was a problem uploading the file. Please try again.');
          }
          console.log('Problem sending file: ', e);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };

  return (
    <div className={`${classes.chatInputContainer} ${'chat-input-container'}`}>
      <Snackbar
        open={Boolean(fileSendError)}
        headline="Error"
        message={fileSendError || ''}
        variant="error"
        handleClose={() => setFileSendError(null)}
      />
      <div
        className={
          clsx(classes.textAreaContainer, { [classes.isTextareaFocused]: isTextareaFocused }) +
          ` ${'chat-text-area-container'}`
        }
      >
        {/* 
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
        <TextareaAutosize
          rowsMin={1}
          rowsMax={3}
          className={classes.textArea}
          aria-label="chat input"
          placeholder="Write a message..."
          onKeyPress={handleReturnKeyPress}
          onChange={handleChange}
          value={messageBody}
          data-cy-chat-input
          ref={textInputRef}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
        />
      </div>

      <Grid container alignItems="flex-end" justify="flex-end" wrap="nowrap" className={`${'chat-grid-container'}`}>
        {/* Since the file input element is invisible, we can hardcode an empty string as its value.
        This allows users to upload the same file multiple times. */}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleSendFile}
          value={''}
          accept={ALLOWED_FILE_TYPES}
        />
        <div className={`${classes.buttonContainer} ${'chat-button-container'}`}>
          <div className={classes.fileButtonContainer}>
            <Button
              className={`${classes.button} ${'chat-file-attachment-button'}`}
              onClick={() => fileInputRef.current?.click()}
              disabled={isSendingFile}
            >
              <FileAttachmentIcon />
            </Button>

            {isSendingFile && <CircularProgress size={24} className={classes.fileButtonLoadingSpinner} />}
          </div>

          <Button
            className={`${customClasses.sendMessageButton} ${'chat-send-message-button'}`}
            onClick={() => handleSendMessage(messageBody)}
            color="primary"
            variant="contained"
            disabled={!isValidMessage}
            data-cy-send-message-button
          >
            <SendMessageIcon />
          </Button>
        </div>
      </Grid>
    </div>
  );
}
