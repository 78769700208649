import React, { SVGProps } from 'react';

export default function ScreenShareIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 12.0329C2 17.1887 5.89667 21.4437 10.89 22L10.89 14.2624L7.55667 14.2624L12.0011 8.68859L16.4456 14.2624L13.1122 14.2624L13.1122 21.9989C18.1044 21.4415 22 17.1876 22 12.0329C22 6.50031 17.5144 2 12 2C6.48556 2 2 6.50031 2 12.0329Z"
        fill="#868E96"
      />
    </svg>
  );
}
