import React from 'react';
import clsx from 'clsx';
import { Link } from '@material-ui/core';
import linkify from 'linkify-it';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  messageContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.5em 0.8em 0.6em',
    margin: '4px 0 0 0',
    wordBreak: 'break-word',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    backgroundColor: '#DAEFFE',
    borderRadius: '8px 2px 8px 8px',
    width: 'calc(100% - 16px)',
    '& p': {
      margin: '0',
    },
  },
  isLocalParticipant: {
    backgroundColor: '#EDEDED',
    borderRadius: '2px 8px 8px 8px',
  },
  messageWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  isLocalParticipantContainer: {
    justifyContent: 'flex-start',
  },
  lang: {
    textTransform: 'uppercase',
  },
  originMessageTitle: {
    fontWeight: 'bold',
    fontSize: '13px',
    color: '#6B7178',
  },
  originMessageBody: {
    lineHeight: '130%',
    color: '#6B7178',
  },
  translatedMessageTitle: {
    fontWeight: 'bold',
    fontSize: '13px',
    color: '#343A40',
  },
  translatedMessageBody: {
    lineHeight: '130%',
    color: '#212529',
  },
});

interface TextMessageProps {
  body: string;
  isLocalParticipant: boolean;
}

// function addLinks(text: string) {
//   const matches = linkify().match(text);
//   if (!matches) return text;
//
//   const results = [];
//   let lastIndex = 0;
//
//   matches.forEach((match, i) => {
//     results.push(text.slice(lastIndex, match.index));
//     results.push(
//       <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
//         {match.text}
//       </Link>
//     );
//     lastIndex = match.lastIndex;
//   });
//
//   results.push(text.slice(lastIndex, text.length));
//   return results;
// }

type bodyOptionsProps = {
  [key: string]: Function;
};

const switchTypeBody: bodyOptionsProps = {
  Origin: createOriginMessageContext,
  Translated: createTranslatedMessageContext,
};

// function createOriginMessageContext(body: any, classes: any) {
//   const message: string | string[] = addLinks(body);
//   let originText: string | string[];
//   let language: string;
//   let messageBody: string | any[] = [];
//   if ((message instanceof Array) === true) {
//     originText = message[0].split(" ")[0];
//     language = message[0].split(" ")[1].split(":")[0];
//     for(let i = 0; i < message.length; i++) {
//       if (i !== 0) {
//         messageBody.push(message[i]);
//       }
//     }
//   } else {
//     originText = (message as string).split(" ")[0];
//     language = (message as string).split(" ")[1].split(":")[0];
//     messageBody = (message as string).split(" ")[2];
//   }

//   return (
//     <>
//       <div>
//         <p className={classes.originMessageTitle}>{originText} ({language})</p>
//         <p className={classes.originMessageBody}>{messageBody}</p>
//       </div>
//     </>
//   )
// }

// function createTranslatedMessageContext(body: any, classes: any) {
//   const message: string | string[] = addLinks(body);
//   // console.log("message", message);
//   let translatedText: string;
//   let translatedText1: string;
//   let language: string;
//   let messageBody: string | any[] = [];
//   if ((message instanceof Array) === true) {
//     translatedText = message[0].split(" ")[0];
//     translatedText1 = message[0].split(" ")[1];
//     language = message[0].split(" ")[1].split(":")[0];
//     for(let i = 0; i < message.length; i++) {
//       if (i !== 0) {
//         messageBody.push(message[i]);
//       }
//     }
//   } else {
//     translatedText = (message as string).split(" ")[0];
//     translatedText1 = (message as string).split(" ")[1];
//     language = (message as string).split(" ")[2].split(":")[0];
//     messageBody = (message as string).split(" ")[3];
//   }

//   return (
//     <>
//       <div>
//         <p className={classes.translatedMessageTitle}>{translatedText} {translatedText1} ({language})</p>
//         <p className={classes.translatedMessageBody}>{messageBody}</p>
//       </div>
//     </>
//   )
// }

function createOriginMessageContext(body: string, classes: any) {
  const message: string[] = body.split(' ');
  // console.log("message 1", message)

  const originText: string = message[0];
  const language: string = message[1].split(':')[0].split('-')[0];
  const messageBody: string = message.slice(2, message.length).join(' ');

  return (
    <>
      <div>
        <p className={classes.originMessageTitle}>
          {originText} <span className={classes.lang}>({language}):</span>
        </p>
        <p className={classes.originMessageBody}>{messageBody}</p>
      </div>
    </>
  );
}

function createTranslatedMessageContext(body: string, classes: any) {
  const message: string[] = body.split(' ');
  // console.log("message 2", message)

  const translatedText: string = message[0];
  const translatedText1: string = message[1];
  const language: string = message[2].split(':')[0];
  const messageBody: string = message.slice(3, message.length).join(' ');

  return (
    <>
      <div>
        <p className={classes.translatedMessageTitle}>
          {translatedText} {translatedText1} <span className={classes.lang}>({language}):</span>
        </p>
        <p className={classes.translatedMessageBody}>{messageBody}</p>
      </div>
    </>
  );
}

export default function TextMessage({ body, isLocalParticipant }: TextMessageProps) {
  let messageType: string;
  // if (typeof addLinks(body) === "string" || addLinks(body) instanceof String) {
  //   messageType = (addLinks(body) as string).split(" ")[0];
  // } else {
  //   messageType = addLinks(body)[0].split(" ")[0];
  // }

  messageType = body.split(' ')[0];
  // console.log('body', body.split(' ')[0]);
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.messageWrapper, {
        [classes.isLocalParticipantContainer]: isLocalParticipant,
      })}
    >
      <div
        className={
          clsx(classes.messageContainer, {
            [classes.isLocalParticipant]: isLocalParticipant,
          }) +
          ' ' +
          'message-container'
        }
      >
        {/* <div>{addLinks(body)}</div> */}
        <div>{switchTypeBody[messageType](body, classes)}</div>
      </div>
    </div>
  );
}
