// TODO: Combine RoomNameScreen and DeviceSelectionScreen.

import React, { ChangeEvent, useEffect, FormEvent } from 'react';
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useAppState } from '../../../state';
import Languages from '../../../shared/languages';
import styleConfig from '../../../shared/styles/styleConfig';

import CircularProgress from '@material-ui/core/CircularProgress';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  tbH5: {
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '-0.02em',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginBottom: '0',
    },
  },
  tbBody1: {
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '-0.02em',
    color: '#6C6C6C',
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  formContainer: {
    margin: '10px 0 20px 0',
    maxWidth: '252px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      margin: '10px 0 0 0',
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    // margin: '0 0 14px 0',
    '& div:not(:last-child)': {
      marginBottom: '0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 8px 0',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  textInputLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
  },
  textField: {
    borderRadius: '6px',
    height: '43px',
    '& fieldset': {
      border: 'unset',
      boxShadow: 'unset',
      outline: 'unset',
    },
    '& input': {
      transition: 'all 150ms ease-in-out',
      borderRadius: '6px',
      backgroundColor: '#ECECEC',
    },
    '& input:focus': {
      boxShadow: 'inset 0 0 0 2px #3a4fc2',
      backgroundColor: '#fff',
    },
  },
  selectContainer: {
    backgroundColor: '#ECECEC',
    borderRadius: '6px',
    width: '100%',
    '&::before': {
      borderBottom: 'unset',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'unset',
    },
  },

  primaryButton: {
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  joiningContainer: {
    height: '316px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SHOP = 'shop';
const USER = 'user';

const ParticipantType = {
  SHOP,
  USER,
};

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  participantLanguageCode: string;
  participantLanguageValue: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  setParticipantLanguageCode: (participantLanguageCode: string) => void;
  setParticipantLanguageValue: (participantLanguageValue: string) => void;
  userType?: string | null;
  setUserType: (userType: string | null) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;

  callHash: string;
  setCallHash: (callHash: string) => void;

  setToRoom: (toRoom: boolean) => void;
}

// let userType: string | null = null;

export default function RoomNameScreen({
  name,
  roomName,
  participantLanguageCode,
  participantLanguageValue,
  setName,
  setRoomName,
  setParticipantLanguageCode,
  setParticipantLanguageValue,
  userType,
  setUserType,
  handleSubmit,
  callHash,
  setCallHash,
  setToRoom,
}: RoomNameScreenProps) {
  const classes = useStyles();
  const customClasses = styleConfig();
  const { user, isFetching } = useAppState();
  const { isConnecting } = useVideoContext();

  const getURLParam = function(param: any) {
    const URLParameters: string = window.location.search.substring(1);
    const urlParams: URLSearchParams = new URLSearchParams(URLParameters);
    return urlParams.get(param);
  };

  useEffect(() => {
    userType = getURLParam('type');
    if (userType) {
      setUserType(userType);
    }
  }, []);
  // http://localhost:3000/room/fds?username=fdsfsdd&type=shop&language-code=en-US&language-name=English%C2%A0(United%C2%A0States)
  useEffect(() => {
    const userName: string | null = getURLParam('username');
    if (userName) {
      // setName(`username:${userName},language:${participantLanguageCode},call_hash:${callHash}`);
      setName(`username:${userName}`);
      // setName(userName);
      // setRoomName(userName);
    }
  }, []);

  useEffect(() => {
    const userCallHash: string | null = getURLParam('call_hash');
    if (userCallHash) {
      setCallHash(userCallHash);
    }
  }, []);

  useEffect(() => {
    const toRoom: string | null = getURLParam('to_room');
    if (toRoom && toRoom === 'true') {
      setToRoom(true);
    }
  }, []);

  useEffect(() => {
    function getUserLanguage() {
      const languageCode: any = getURLParam('language_code');
      const languageValue: any = getURLParam('language_name');

      if (!languageCode && !languageValue) {
        const browserLanguage = window.navigator.language.slice(0, 2);
        for (let i = 0; i < Languages.length; i++) {
          const langIndex: number = Languages[i][1].indexOf(browserLanguage);
          if (langIndex !== -1) {
            setParticipantLanguageCode(Languages[i][1]);
            setParticipantLanguageValue(Languages[i][0]);
          }
        }
      }
    }

    function getShopLanguage() {
      const shopLanguageCode: any = getURLParam('language_code');
      const shopLanguageValue: any = getURLParam('language_name');
      setParticipantLanguageCode(shopLanguageCode);
      setParticipantLanguageValue(shopLanguageValue);
    }

    if (userType === ParticipantType.SHOP) {
      getShopLanguage();
    } else {
      getUserLanguage();
    }
  }, []);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    // setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    // setRoomName(event.target.value);
  };

  const handleParticipantLanguage = (event: any) => {
    // console.log("event", event.target.value);
    setParticipantLanguageCode(event.target.value);
    for (let i = 0; i < Languages.length; i++) {
      const langIndex: number = Languages[i].indexOf(event.target.value);
      if (langIndex !== -1) {
        setParticipantLanguageValue(Languages[i][0]);
      }
    }
  };

  if (isFetching || isConnecting) {
    return (
      <div className={classes.joiningContainer}>
        <Grid container justify="center" alignItems="center" direction="column">
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
          <div style={{ display: 'none' }}>
            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
              Joining Meeting
            </Typography>
          </div>
        </Grid>
      </div>
    );
  }

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.tbH5} style={{ display: 'none' }}>
        Call {roomName}
      </Typography>
      {userType !== ParticipantType.SHOP ? (
        <Typography variant="body1" className={classes.textInputLabel}>
          Choose language
        </Typography>
      ) : (
        <></>
      )}

      <Typography variant="body1" className={classes.tbBody1} style={{ display: 'none' }}>
        {hasUsername
          ? "Enter the name of a room you'd like to join."
          : "Enter your name and the name of a room you'd like to join"}
      </Typography>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <div className={classes.inputContainer} style={{ display: 'none' }}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel htmlFor="input-user-name" className={classes.textInputLabel}>
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                className={classes.textField}
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
        </div>
        <div className={classes.inputContainer} style={{ display: 'none' }}>
          {/* <div className={classes.inputContainer}> */}
          <div className={classes.textFieldContainer}>
            <InputLabel htmlFor="input-room-name" className={classes.textInputLabel}>
              Room Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              className={classes.textField}
              value={roomName}
              onChange={handleRoomNameChange}
            />
          </div>
        </div>
        {userType !== ParticipantType.SHOP ? (
          <div className={classes.inputContainer}>
            <div className={classes.textFieldContainer}>
              <InputLabel htmlFor="input-room-language" className={classes.textInputLabel} style={{ display: 'none' }}>
                Choose language
              </InputLabel>
              <Select
                className={classes.selectContainer}
                onChange={handleParticipantLanguage}
                value={participantLanguageCode}
                name={participantLanguageValue}
              >
                {Languages.map(language => (
                  <MenuItem key={language[1]} value={language[1]}>
                    {language[0]}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        ) : (
          <></>
        )}

        <Grid container justify="flex-start" style={{ display: 'none' }}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName || !participantLanguageCode}
            className={customClasses.primaryButton + ' ' + classes.primaryButton}
          >
            <svg width="50" height="50" viewBox="0 0 19 19" fill="transparent" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9.5" cy="9.5" r="9.5" fill="transparent"></circle>
              <path
                d="M15 7.70648C15 7.39556 14.6608 7.20351 14.3942 7.36348L10.5717 9.657C10.3127 9.81236 10.3127 10.1876 10.5717 10.343L14.3942 12.6365C14.6608 12.7965 15 12.6044 15 12.2935V7.70648Z"
                fill="white"
              ></path>
              <path
                d="M10.0538 7H4.94624C4.42364 7 4 7.38376 4 7.85714V12.1429C4 12.6162 4.42364 13 4.94624 13H10.0538C10.5764 13 11 12.6162 11 12.1429V7.85714C11 7.38376 10.5764 7 10.0538 7Z"
                fill="white"
              ></path>
            </svg>
            Call
          </Button>
        </Grid>
      </form>
    </>
  );
}
