import React, { useEffect } from 'react';

// import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import styleConfig from '../../../shared/styles/styleConfig';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
    showText: {
      // '& .button-text': {
      //   display: 'block',
      // },
      [theme.breakpoints.down('sm')]: {
        '& .button-text': {
          display: 'none',
        },
      },
    },
    hideIcon: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  })
);

export default function EndCallButton(props: { urlRedirect?: string; className?: string }) {
  const classes = useStyles();
  const customClasses = styleConfig();
  const { room } = useVideoContext();

  const handleSubmit = () => {
    room!.disconnect();
    window.location.href = props.urlRedirect as string;
  };

  return (
    // <Button onClick={() => room!.disconnect()} className={clsx(classes.button, props.className)} data-cy-disconnect>

    // <Button
    //   onClick={() => room!.disconnect()}
    //   className={customClasses.dangerButton + ' ' + classes.showText}
    //   data-cy-disconnect
    // >
    //   <span className={'button-text'}>Disconnect</span>
    //   <svg
    //     className={classes.hideIcon}
    //     width="23"
    //     height="9"
    //     viewBox="0 0 23 9"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path
    //       d="M2.95323 8.4525L1.18438 6.68365C1.0195 6.52011 0.890665 6.32387 0.806124 6.1075C0.721582 5.89112 0.683198 5.65939 0.693429 5.42714C0.70366 5.19488 0.762281 4.96723 0.865538 4.75876C0.968795 4.55029 1.11441 4.36559 1.29306 4.21651C3.30815 2.5957 5.6744 1.46941 8.20167 0.928136C10.5344 0.405953 12.9524 0.403556 15.2841 0.921115C17.8207 1.46086 20.1934 2.59079 22.2098 4.21937C22.3876 4.36766 22.5325 4.55141 22.6353 4.75891C22.7381 4.96642 22.7965 5.19314 22.8068 5.42465C22.8171 5.65615 22.7791 5.88736 22.6952 6.10356C22.6113 6.31976 22.4834 6.51621 22.3195 6.6804L20.5471 8.45276C20.2632 8.74229 19.8839 8.91892 19.48 8.94973C19.076 8.98053 18.6749 8.86341 18.3514 8.6202C17.7105 8.12893 17.016 7.71186 16.281 7.37693C15.9905 7.24538 15.744 7.03322 15.5706 6.76561C15.3972 6.49799 15.3043 6.18613 15.3028 5.86698L15.3043 4.36787C12.9871 3.73276 10.5403 3.73518 8.22186 4.37489L8.22038 5.874C8.21827 6.19316 8.12473 6.5052 7.95082 6.77316C7.77691 7.04112 7.52993 7.25376 7.23914 7.38589C6.50352 7.72228 5.80815 8.14074 5.1663 8.63327C4.83891 8.87985 4.43255 8.99768 4.02451 8.96435C3.61646 8.93102 3.2352 8.74885 2.95323 8.4525Z"
    //       fill="white"
    //     />
    //   </svg>
    // </Button>

    // <a onClick={() => room!.disconnect()} className={customClasses.dangerButton + ' ' + classes.showText} href={props.urlRedirect}>
    <Button
      onClick={handleSubmit}
      className={customClasses.dangerButton + ' ' + classes.showText + ' ' + customClasses.roomControlBtnCircle}
    >
      <svg
        // className={classes.hideIcon}
        width="23"
        height="9"
        viewBox="0 0 23 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.95323 8.4525L1.18438 6.68365C1.0195 6.52011 0.890665 6.32387 0.806124 6.1075C0.721582 5.89112 0.683198 5.65939 0.693429 5.42714C0.70366 5.19488 0.762281 4.96723 0.865538 4.75876C0.968795 4.55029 1.11441 4.36559 1.29306 4.21651C3.30815 2.5957 5.6744 1.46941 8.20167 0.928136C10.5344 0.405953 12.9524 0.403556 15.2841 0.921115C17.8207 1.46086 20.1934 2.59079 22.2098 4.21937C22.3876 4.36766 22.5325 4.55141 22.6353 4.75891C22.7381 4.96642 22.7965 5.19314 22.8068 5.42465C22.8171 5.65615 22.7791 5.88736 22.6952 6.10356C22.6113 6.31976 22.4834 6.51621 22.3195 6.6804L20.5471 8.45276C20.2632 8.74229 19.8839 8.91892 19.48 8.94973C19.076 8.98053 18.6749 8.86341 18.3514 8.6202C17.7105 8.12893 17.016 7.71186 16.281 7.37693C15.9905 7.24538 15.744 7.03322 15.5706 6.76561C15.3972 6.49799 15.3043 6.18613 15.3028 5.86698L15.3043 4.36787C12.9871 3.73276 10.5403 3.73518 8.22186 4.37489L8.22038 5.874C8.21827 6.19316 8.12473 6.5052 7.95082 6.77316C7.77691 7.04112 7.52993 7.25376 7.23914 7.38589C6.50352 7.72228 5.80815 8.14074 5.1663 8.63327C4.83891 8.87985 4.43255 8.99768 4.02451 8.96435C3.61646 8.93102 3.2352 8.74885 2.95323 8.4525Z"
          fill="white"
        />
      </svg>
      {/* <span style={{ marginLeft: '10px' }} className={'button-text'}>
        Decline
      </span> */}
    </Button>
  );
}
