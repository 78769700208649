import React, { SVGProps } from 'react';

export default function VideoOffIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 14.25H11.1593L1.60875 4.69875C1.5405 4.86975 1.5 5.055 1.5 5.25V12.75C1.5 13.5772 2.17275 14.25 3 14.25ZM13.5 5.25C13.5 4.42275 12.8273 3.75 12 3.75H4.8105L2.78025 1.71975L1.71975 2.78025L15.2197 16.2802L16.2803 15.2197L13.5 12.4395V10.2502L16.5 12.75V5.25L13.5 7.74975V5.25Z"
        fill="#E03131"
      />
    </svg>
  );
}
