import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
// import Swoosh from './swoosh';
// import VideoLogo from './VideoLogo';
// import TwilioLogo from './TwilioLogo';
import lilyBg from './swoosh';
import tbBackground from './tbBackground';
import TbLogo from '../../icons/tbLogo';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    flexDirection: 'column',
    backgroundImage: tbBackground,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  loginContainer: {
    // height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    // maxHeight: '580px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: '650px',
    },
  },
  container: {
    position: 'relative',
    flex: '2',
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
    },
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    // height: '380px',
    borderRadius: '6px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: lilyBg,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
      display: 'none',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        // height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    // padding: '40px 92px 40px 40px',
    padding: '24px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '14px',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  tbLogoContainer: {
    display: 'flex',
    alignItems: 'baseline',
    // flex: '1',
    marginBottom: '70px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
      marginTop: '20px',
      flex: 'unset',
    },
  },
  preJoinLoginContainer: {
    // maxHeight: '580px',
    maxHeight: '505px',
    [theme.breakpoints.down('sm')]: {
      // maxHeight: '400px',
      padding: '20px 0',
    },
  },
  deviceSelectionScreenLoginContainer: {
    // maxHeight: '505px',
    [theme.breakpoints.down('sm')]: {
      // maxHeight: '650px',
      maxHeight: 'unset',
      padding: '20px 0',
    },
  },
  preJoinInnerContainer: {
    width: '820px',
    [theme.breakpoints.down('sm')]: {
      // width: 'calc(100% - 40px)',
      width: '280px',
    },
  },
  deviceSelectionScreenInnerContainer: {
    // width: '888px',
    width: '592px',
    // height: '380px',
    [theme.breakpoints.down('sm')]: {
      // width: 'calc(100% - 40px)',
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  loginStep?: number;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <div className={classes.background}>
      <div className={classes.tbLogoContainer}>
        <a href="http://2beinstore.com/">
          <TbLogo />
        </a>
      </div>
      <div
        className={`${classes.loginContainer}  ${
          props.loginStep === 0 ? classes['preJoinLoginContainer'] : classes['deviceSelectionScreenLoginContainer']
        }`}
      >
        {/* <TwilioLogo className={classes.twilioLogo} /> */}

        {user && location.pathname !== '/login' && <UserMenu />}
        <div className={classes.container}>
          <div
            className={`${classes.innerContainer} ${
              props.loginStep === 0 ? classes['preJoinInnerContainer'] : classes['deviceSelectionScreenInnerContainer']
            }`}
          >
            <div className={classes.swooshContainer}>
              {/* <div className={classes.logoContainer}>
                <VideoLogo />
                <Typography variant="h6" className={classes.title}>
                  Twilio Programmable Video
                </Typography>
              </div> */}
            </div>
            <div className={classes.content}>{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
